// @flow strict
import React from 'react'
import styled from 'styled-components'
import SiteLink from './sitelink'

const Container = styled.div`
  width: 100%;
  min-height: 4rem;
  margin-bottom: 1.45rem;
`

const Navbar = styled.nav`
  display: flex;
  padding: 1.45rem 1.0875rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const Links = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: 700px) {
    width: 30%;
  }
`

const Header = ({ siteTitle }: { siteTitle: string }) => (
  <Container>
    <Navbar>
      <h1 style={{ margin: 0 }}>
        <SiteLink animate={false} to="/">
          {siteTitle}
        </SiteLink>
      </h1>
      <Links>
        <SiteLink to="/">home</SiteLink>
        <SiteLink to="/about">about</SiteLink>
        <SiteLink to="/contact">contact</SiteLink>
        <SiteLink to="/articles">articles</SiteLink>
      </Links>
    </Navbar>
  </Container>
)

export default Header
