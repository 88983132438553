// @flow strict
import React from 'react'
import type { Node } from 'react'
import styled from 'styled-components'

import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

const Container = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 1rem 1rem;
`

type Props = {
  children: Node,
  location: {
    pathname: string,
  },
}

const Layout = ({ children, location }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header location={location} siteTitle={data.site.siteMetadata.title} />
        <Container>{children}</Container>
      </>
    )}
  />
)

export default Layout
