import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const SiteLink = styled(Link)`
  text-decoration: none;
  padding: 1rem;
  color: black;
  ${({ animate = true }) =>
    animate &&
    `
    &:: after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #000;
      transition: width 0.3s;
    }
    &:hover {
      &:: after {
        width: 100%;
      }
    }
  `}
`

export default props => {
  const isCurrentPath = props.pathname === props.to
  return (
    <SiteLink
      {...props}
      to={isCurrentPath ? '/' : props.to}
      children={isCurrentPath ? 'home' : props.children}
    />
  )
}
